import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import {
  IGetInteractiveLearningParams,
  IGetInteractiveLearningResponse,
  ICreateInteractiveLearningGamePayload,
  ICreateInteractiveLearningGameResponse,
  IUpdateInteractiveLearningPayload,
  IGetInteractiveLearningGameDetailResponse,
} from './interactive-learning.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 取得遊戲資料 */
  getInteractiveLearning(
    params: IGetInteractiveLearningParams,
  ): TApiResponse<IGetInteractiveLearningResponse> {
    return axios.get(`${apiPrefix}/backend/interactive-learning`, { params });
  },
  /** 取得單筆遊戲資料 */
  getInteractiveLearningGameDetail(
    id: string,
  ): TApiResponse<IGetInteractiveLearningGameDetailResponse> {
    return axios.get(`${apiPrefix}/backend/interactive-learning/${id}`);
  },
  /** 建立遊戲資料 */
  createInteractiveLearningGame(
    payload: ICreateInteractiveLearningGamePayload,
  ): TApiResponse<ICreateInteractiveLearningGameResponse> {
    return axios.post(
      `${apiPrefix}/backend/interactive-learning/game`,
      payload,
    );
  },
  /** 修改遊戲資料 */
  updateInteractiveLearningGame(
    id: string,
    payload: IUpdateInteractiveLearningPayload,
  ): TApiResponse<null> {
    return axios.patch(
      `${apiPrefix}/backend/interactive-learning/${id}`,
      payload,
    );
  },
  /** 批次下架遊戲資料 */
  batchDisableInteractiveLearning(payload: string[]): TApiResponse<null> {
    return axios.patch(
      `${apiPrefix}/backend/interactive-learning/batchDisable`,
      payload,
    );
  },
});
